import React from "react";
import "./askCompany.css";

const AskCompany = () => {
  return (
    <section className="askCompany">
      <iframe className='iframe_askcompany'
        src="https://geekle.us/0a_qa233?event=qa233"
        width="100%"
        height="512"
        align="center"
        title='askCompany_frame'
      >
      </iframe>
    </section>
  );
};
export default AskCompany;
