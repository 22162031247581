import React, { useState, useEffect } from 'react';
import './Timer.css';
import CountDown from '../../CountDown/CountDown';

const Timer = () => {
	return (
		<div className='timer_cont'>
			<h4>
				🔥 Senior Day ticket prices will increase from $69 to $99! Sale ends
				this Saturday! 🔥
			</h4>
			<CountDown />
		</div>
	);
};

export default Timer;
