import React, { useEffect, useState } from 'react';

const CountDownText = () => {
	const packages = [
		{
			// price: `Time’s running out! Act now!`,
			title:
				'🔥 Senior Day ticket prices will increase from $69 to $99! Sale ends this Saturday! 🔥',
		},
		{
			// price: `Time’s running out! Act now!`,
			title:
				'🔥 Senior Day ticket prices will increase from $69 to $99! Sale ends this Saturday! 🔥',
		},
	];

	const [activePackage, setActivePackage] = useState(0);

	// Змінюємо активний пакет кожні 3,5 секунди
	useEffect(() => {
		const interval = setInterval(() => {
			setActivePackage(prev => (prev < packages.length - 1 ? prev + 1 : 0));
		}, 3500);
		return () => clearInterval(interval);
	}, [packages.length]);

	return (
		<div className='countdown_text_block'>
			<p class='countdown-descr'>{packages[activePackage].title}</p>
			<p class='countdown-descr'>{packages[activePackage].price}</p>
		</div>
	);
};

export default CountDownText;
