import React from "react";
import "./enterprise.css";

const Enterprise = () => {
  return (
    <section className="enterprise" id="enterprise">
      <iframe className='iframe_enterprise'
        src="https://geekle.us/0e_qa233?event=qa233"
        width="100%"
        height="494"
        align="center"
        title='enterprise_frame'
      >
      </iframe>
    </section>
  );
};
export default Enterprise;
